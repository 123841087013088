/* src/App.css */

/* src/App.css */

/* src/App.css */

body {
  font-family: 'Noto Sans SC', sans-serif;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 其他样式保持不变 */



/* 其他样式保持不变 */


.header {
  background: #333;
  color: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  margin: 0;
  font-size: 24px;
}

.tabs {
  display: flex;
  gap: 15px;
}

.tab-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 15px;
  transition: background 0.3s;
}

.tab-button:hover,
.tab-button.active {
  background: #555;
  border-radius: 5px;
}

.container {
  display: flex;
  padding: 20px;
  gap: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.server-list {
  width: 75%;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.no-servers {
  padding: 20px;
  text-align: center;
  color: #555;
}

.filter-panel {
  width: 25%;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.filter-panel h3 {
  margin: 0 0 20px;
  font-size: 22px;
  color: #333;
}

.filter-panel select,
.filter-panel input[type='text'] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
}

.filter-panel button {
  width: 100%;
  padding: 12px;
  background: #1976d2;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 10px;
}

.filter-panel button:hover {
  background: #1565c0;
}

.refresh {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #777;
  cursor: pointer;
}

.refresh i {
  margin-right: 5px;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
  }
  .server-list,
  .filter-panel {
    width: 100%;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  max-width: 500px;
  position: relative;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin: 10px 0 5px;
}

.modal-content select {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-content button {
  padding: 10px;
  background: #1976d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background: #1565c0;
}
