/* src/components/ServerDetails.css */

.server-details-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  background: #f0f0f0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  text-decoration: underline;
}

.back-button {
  margin-left: auto;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.back-button:hover {
  color: #555;
}

.server-details-container {
  padding: 20px;
  background: #fff;
  flex-grow: 1;
  overflow-y: auto;
}

.server-title-section {
  text-align: left;
  margin-bottom: 20px;
}

.server-title {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.server-rules {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.server-provider {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.provider-logo {
  background: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.server-basic-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.server-basic-info div {
  font-size: 16px;
  color: #333;
}

.central-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.central-button {
  padding: 10px 20px;
  background: #1976d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s;
}

.central-button:hover {
  background: #1565c0;
}

.server-settings {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.server-settings h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.settings-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.settings-item label {
  width: 200px;
  font-weight: bold;
  color: #555;
}

.ratio-settings {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #555;
}

.map-rotation {
  margin-bottom: 20px;
}

.map-rotation h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.maps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.map-item {
  position: relative;
  background: #000;
  border-radius: 5px;
  overflow: hidden;
}

.map-image-container {
  position: relative;
}

.map-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.map-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
}
