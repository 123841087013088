/* src/components/FilterPanel.css */
.filter-panel {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px; /* 调整宽度 */
  background-color: #fff;
}

.refresh {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1890ff;
  margin-bottom: 10px;
}

.refresh:hover {
  text-decoration: underline;
}

.filter-panel h3 {
  margin-bottom: 15px;
}

.filter-panel input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-category {
  margin-bottom: 15px;
}
.filter-button {
  width: 100%;
  padding: 10px;
  background-color: #1890ff; /* 设置为蓝色 */
  color: #fff;               /* 字体颜色为白色 */
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-button:hover {
  background-color: #40a9ff; /* 调整悬停时的颜色 */
}

/* 保持按钮在点击和获得焦点时为蓝色 */
.filter-button:active,
.filter-button:focus {
  background-color: #1890ff;
  color: #fff;
}

.filter-options {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background-color: #fafafa;
}

.filter-options label {
  display: block;
  margin-bottom: 5px;
}

.player-count-filter {
  margin-bottom: 15px;
}

.slider-container {
  margin: 10px auto 0 auto; /* 上外边距10px，左右自动居中 */
  width: 350px; /* 缩小滑动条的宽度 */
}

/* 确保 rc-slider 填满 slider-container */
.slider-container .rc-slider {
  width: 100%;
}

.range-display {
  margin-bottom: 10px;
}

.ignore-bot-filter {
  margin-bottom: 15px;
}

.filter-panel button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-panel button:hover {
  background-color: #40a9ff;
}

.filter-panel button:last-child {
  background-color: #1890ff;
}

.filter-panel button:last-child:hover {
  background-color: #1890ff;
}